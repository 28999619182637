
import { defineComponent, ref, Ref, computed, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRoute();
    const collapsed: Ref<boolean> = ref(false);

    const linkClass = computed(() => ({
      collapse: true,
      visible: collapsed.value,
    }));

    function toggleCollapse() {
      collapsed.value = !collapsed.value;
    }

    watch(
      () => router.fullPath,
      () => {
        collapsed.value = false;
      }
    );

    return {
      toggleCollapse,
      linkClass,
    };
  },
});
