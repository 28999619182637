import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {

        el: to.hash,
        top: 200,
        behavior: 'smooth'
      }
    }

    return { top: 0, left: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const { meta = {} } = to
  const { title } = meta

  if (title) {
    document.title = `${title} | MM Maritime Services`
  }

  next()
})

export default router
