
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'homepage',
    path: '/home',
    component: () => import('@/views/Homepage.vue'),
    meta: {
      title: 'Homepage'
    }
  },{
    name:'about',
    path: '/about',
    component: () => import('@/views/About.vue'),
    meta: {
      title: 'About Us'
    }
  },
  {
    name: '404',
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

export default routes
