
import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
  setup() {
    const year: Ref<number> = ref(new Date().getFullYear());

    return { year };
  },
});
