
import { defineComponent } from "vue";

import PageNavbar from "@/components/PageNavbar.vue";
import PageFooter from "@/components/PageFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    PageNavbar,
    PageFooter,
  },
});
